import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import { INPUT_MAX_LENGTH } from 'utils/constants';
import { isCheckboxType, isInputsGroup } from 'utils/newsletter';

import './Input.scss';

const Input = ({
  InputsGroupFieldID,
  fieldID,
  label,
  type,
  mandatoryValidationMessage,
  additionalValidationMessage,
  regexPattern,
  register,
  errors,
  onChangeCheckbox,
  handleValidation,
  isRequired = true,
}: SignUpPageTypes.InputType) => (
  <div
    className={classNames('signup-form__control', {
      [`signup-form__control--${type}`]: type,
    })}
  >
    <div
      className={classNames('signup-form__input-container', {
        [`signup-form__input-container--${type}`]: type,
      })}
    >
      <input
        className={classNames('signup-form__input', {
          [`signup-form__input--${type}`]: type,
          'signup-form__input--error': errors && errors[fieldID],
        })}
        type={type}
        id={isInputsGroup(type) && InputsGroupFieldID ? InputsGroupFieldID : label}
        name={fieldID}
        maxLength={INPUT_MAX_LENGTH}
        value={isInputsGroup(type) ? label : undefined}
        ref={register({
          required: isRequired,
          validate: () => handleValidation(type, fieldID, isRequired, regexPattern),
        })}
        onChange={(e) => isCheckboxType(type) && onChangeCheckbox(e, fieldID)}
      />
      <label
        className={classNames('signup-form__label', {
          [`signup-form__label--${type}`]: type,
          'signup-form__label--error': errors && errors[fieldID],
        })}
        htmlFor={isInputsGroup(type) && InputsGroupFieldID ? InputsGroupFieldID : label}
      >
        <DangerouslySetInnerHtml html={label} />
      </label>
    </div>
    {errors && errors[fieldID] && !isInputsGroup(type) ? (
      <p className="signup-form__error-item">
        {additionalValidationMessage || mandatoryValidationMessage}
      </p>
    ) : null}
  </div>
);

export const InputType = graphql`
  fragment InputType on InputType {
    fieldID
    label
    type
    additionalValidationMessage
    regexPattern
    isRequired
  }
`;

export default Input;
