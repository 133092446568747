import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import './InputSelect.scss';

const InputSelect = ({
  label,
  fieldID,
  placeholder,
  options,
  mandatoryValidationMessage,
  register,
  errors,
}: SignUpPageTypes.InputSelectType) => (
  <div className="signup-form__input-container">
    <label htmlFor={fieldID}>{label}</label>
    <select
      name={fieldID}
      id={fieldID}
      ref={register({
        required: true,
      })}
      className={classNames('signup-form__select', {
        'signup-form__select--error': errors && errors[fieldID],
      })}
    >
      <option value="" disabled selected>
        {placeholder}
      </option>
      {options.map(({ value }: SignUpPageTypes.OptionType) => (
        <option key={value} {...{ value }}>
          {value}
        </option>
      ))}
    </select>
    <i className="fas fa-caret-right" />
    {errors && errors[fieldID] ? (
      <p className="signup-form__error-item">{mandatoryValidationMessage}</p>
    ) : null}
  </div>
);

export const InputSelectType = graphql`
  fragment InputSelectType on InputSelectType {
    fieldID
    label
    placeholder
    options {
      value
    }
  }
`;

export default InputSelect;
