import React from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import { isCheckboxFieldID } from 'utils/newsletter';

import Input from '../Input';

const InputsGroup = ({
  radioGroupLabel,
  mandatoryValidationMessage,
  newsletterValidationMessage,
  tCvalidationMessage,
  radioCheckBoxes,
  fieldID,
  register,
  errors,
  setValue,
  handleValidation,
}: SignUpPageTypes.InputsGroupType) => {
  const onChangeCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    checkboxFieldID: string
  ): void => setValue(checkboxFieldID, e.target.value);

  const validationMessage = {
    TC: tCvalidationMessage,
    newsletter: newsletterValidationMessage,
  };

  return (
    <div
      className="signup-form__control signup-form__control--radio-group"
      data-testid="signup-form-group-control"
    >
      <DangerouslySetInnerHtml
        className="signup-form__label signup-form__label--radio-group"
        html={radioGroupLabel}
      />
      <div className="signup-form__control-container">
        {radioCheckBoxes.map(
          ({ fieldID: InputsGroupFieldID, label, type }: SignUpPageTypes.RadioCheckBoxType) => (
            <div className="signup-form__control signup-form__control--radio" key={label}>
              <Input
                {...{
                  InputsGroupFieldID,
                  label,
                  type,
                  fieldID,
                  mandatoryValidationMessage,
                  register,
                  errors,
                  onChangeCheckbox,
                  handleValidation,
                }}
              />
            </div>
          )
        )}
        {errors && errors[fieldID] ? (
          <DangerouslySetInnerHtml
            className="signup-form__error-item"
            html={
              isCheckboxFieldID(fieldID) ? validationMessage[fieldID] : mandatoryValidationMessage
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export const InputsGroupType = graphql`
  fragment InputsGroupType on InputsGroupType {
    fieldID
    radioGroupLabel
    radioCheckBoxes {
      fieldID
      label
      type
    }
  }
`;

export default InputsGroup;
