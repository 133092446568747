import React, { useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import './FormAccordion.scss';

const FormAccordion = ({ question, answer }: SignUpPageTypes.FormAccordionType) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className="form-accordion-item" data-testid="form-accordion-item">
      <div
        className={classNames('form-accordion-item__toggle', {
          'form-accordion-item__toggle--active': isOpen,
        })}
        role="button"
        tabIndex={0}
        onClick={handleToggleAccordion}
        onKeyDown={handleToggleAccordion}
      >
        <p className="form-accordion-item__question">{question}</p>
        <i className="fas fa-caret-right" />
      </div>
      {isOpen ? (
        <div className="form-accordion-item__content">
          <p className="form-accordion-item__answer">{answer}</p>
        </div>
      ) : null}
    </div>
  );
};

export const AccordionType = graphql`
  fragment AccordionType on AccordionType {
    question
    answer
  }
`;

export default FormAccordion;
