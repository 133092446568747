import React from 'react';

import Carousel from 'common/Carousel';
import AdditionalInfo from 'components/AdditionalInfo';
import InfoBanner from 'components/InfoBanner';

import './ThankYouPage.scss';

const ThankYouPage = ({
  mainCarousel,
  additionalInfo,
  infoBanner,
}: PageContent.ThankYouPageType) => (
  <div className="thank-you-page">
    <Carousel
      {...{
        carouselImages: mainCarousel,
        className: 'main-carousel',
      }}
    />
    <AdditionalInfo {...{ ...additionalInfo }} />
    <InfoBanner {...{ infoBanner }} />
  </div>
);

export default ThankYouPage;
