import React, { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { graphql } from 'gatsby';
import Amplify, { API } from 'aws-amplify';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import { CHECKBOX_DISAGREEMENT, SIGNUP_FORM_ITEM_TYPE } from 'utils/constants';
import { isCheckboxType, prepareCdpData, switchToEmptyValues } from 'utils/newsletter';

// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import awsconfig from '../../../aws-exports';
import FormAccordion from './FormAccordion';
import Input from './Input';
import InputSelect from './InputSelect';
import InputsGroup from './InputsGroup';

import './SignUpForm.scss';

Amplify.configure(awsconfig);

const SignUpFormCard = ({
  signUpFormItems,
  mandatoryValidationMessage,
  newsletterValidationMessage,
  tCvalidationMessage,
  title,
  description,
  submitButton: { ariaLabel: submitAriaLabel, backgroundColor, size, label },
  ariaLabel,
  handleHiddingForm,
  formType,
  hiddenForm,
  setIsFormSentSuccessfully,
}: SignUpPageTypes.SignUpFormCardType) => {
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const { register, errors, handleSubmit, getValues, setValue } = useForm({
    mode: 'onChange',
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleForm = useCallback(
    async (formData: SignUpPageTypes.FormDataType) => {
      setIsFormDisabled(true);
      const gRecaptchaResponse = await executeRecaptcha!('newsletterSignUp');
      const formWithEmptyValues = switchToEmptyValues(formData) as SignUpPageTypes.FormDataType;

      API.post(
        'betterhullnewsletter',
        `/betterhullnewsletter?market=${formType}&g-recaptcha-response=${gRecaptchaResponse}`,
        {
          body: prepareCdpData(formWithEmptyValues, formType),
        }
      )
        .then(() => setIsFormSentSuccessfully(true))
        .catch((error) => console.error(error.response.data))
        .finally(() => setIsFormDisabled(false));
    },
    [setIsFormSentSuccessfully, executeRecaptcha, formType]
  );

  const handleValidation = (
    type: string,
    fieldID: string,
    isRequired: boolean,
    regexPattern?: string
  ): boolean => {
    let validated = true;
    const fieldValue = getValues(fieldID);

    if (isCheckboxType(type) && fieldValue.includes(CHECKBOX_DISAGREEMENT)) {
      validated = false;
    }

    if (regexPattern) {
      const regex = new RegExp(regexPattern);
      validated = regex.test(fieldValue) || (!isRequired && !fieldValue);
    }

    return validated;
  };

  const isFormShown = formType === hiddenForm;

  return (
    <div
      className={classNames('signup-form-card', {
        [`signup-form-card__${formType}`]: formType,
        'signup-form-card--open': isFormShown,
      })}
    >
      <div
        className="signup-form-card__title-container"
        onClick={() => handleHiddingForm(formType)}
        onKeyDown={() => handleHiddingForm(formType)}
        role="button"
        tabIndex={0}
      >
        <h3
          className={classNames('signup-form-card__title', {
            'signup-form-card__title--open': isFormShown,
          })}
        >
          {title}
        </h3>
      </div>
      {isFormShown ? (
        <div
          className={classNames('signup-form-card__content', {
            'signup-form-card__content--open': isFormShown,
          })}
        >
          <DangerouslySetInnerHtml html={description} className="signup-form-card__description" />
          <form onSubmit={handleSubmit(handleForm)} aria-label={ariaLabel} className="signup-form">
            {signUpFormItems.map(
              (
                {
                  structure,
                  accordion,
                  input,
                  inputsGroup,
                  rte,
                  inputSelect,
                }: SignUpPageTypes.SignUpFormItemType,
                index
              ) =>
                ({
                  [SIGNUP_FORM_ITEM_TYPE.accordion]: (
                    <FormAccordion key={index!} {...{ ...accordion }} />
                  ),
                  [SIGNUP_FORM_ITEM_TYPE.input]: (
                    <Input
                      key={index!}
                      {...{
                        ...input,
                        mandatoryValidationMessage,
                        register,
                        errors,
                        handleValidation,
                      }}
                    />
                  ),
                  [SIGNUP_FORM_ITEM_TYPE.inputSelect]: (
                    <InputSelect
                      key={index!}
                      {...{
                        ...inputSelect,
                        mandatoryValidationMessage,
                        register,
                        errors,
                      }}
                    />
                  ),
                  [SIGNUP_FORM_ITEM_TYPE.inputsGroup]: (
                    <InputsGroup
                      key={index!}
                      {...{
                        ...inputsGroup,
                        mandatoryValidationMessage,
                        newsletterValidationMessage,
                        tCvalidationMessage,
                        register,
                        errors,
                        setValue,
                        handleValidation,
                      }}
                    />
                  ),
                  [SIGNUP_FORM_ITEM_TYPE.rte]: (
                    <DangerouslySetInnerHtml
                      key={index!}
                      className="signup-form__rte"
                      html={rte?.text}
                    />
                  ),
                }[structure])
            )}
            <div className="signup-form-card__button-wrapper">
              <Button
                className="signup-form-card__button"
                ariaLabel={submitAriaLabel}
                backgroundColor={backgroundColor}
                size={size}
                type="submit"
                label={label}
                disabled={isFormDisabled}
              />
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
};

export const SignUpFormCardType = graphql`
  fragment SignUpFormCardType on SignUpFormCardType {
    ariaLabel
    title
    description
    mandatoryValidationMessage
    emailValidationMessage
    newsletterValidationMessage
    tCvalidationMessage
    submitButton {
      ...ButtonType
    }
    signUpFormItems {
      structure
      rte {
        text
      }
      accordion {
        ...AccordionType
      }
      input {
        ...InputType
      }
      inputSelect {
        ...InputSelectType
      }
      inputsGroup {
        ...InputsGroupType
      }
    }
  }
`;

export default SignUpFormCard;
