import React, { FC, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Carousel from 'common/Carousel';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import AdditionalInfo from 'components/AdditionalInfo';
import InfoBanner from 'components/InfoBanner';
import SignUpFormCard from 'components/SignUpFormCard';
import ThankYouPage from 'components/ThankYouPage';
import { FORM_TYPE } from 'utils/constants';

const SignUpPage: FC<SignUpPageTypes.SignUpPageProps> = ({
  data: {
    signUpPage: {
      urls,
      seo,
      mainCarousel,
      additionalInfo,
      invidualForm,
      organisationForm,
      infoBanner,
      thankYouPage,
    },
  },
}) => {
  const [hiddenForm, setHiddenForm] = useState('');
  const [isFormSentSuccessfully, setIsFormSentSuccessfully] = useState(false);

  const handleHiddingForm = (formType: string): void =>
    setHiddenForm((prevState) => (formType === prevState ? '' : formType));

  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {isFormSentSuccessfully ? (
        <ThankYouPage {...{ ...thankYouPage }} />
      ) : (
        <>
          <Carousel
            {...{
              carouselImages: mainCarousel,
              className: 'main-carousel',
            }}
          />
          <AdditionalInfo {...{ ...additionalInfo }} />
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
            scriptProps={{ async: true, defer: true, appendTo: 'body' }}
          >
            <SignUpFormCard
              {...{
                ...invidualForm,
                handleHiddingForm,
                hiddenForm,
                formType: FORM_TYPE.individual,
                setIsFormSentSuccessfully,
              }}
            />
            <SignUpFormCard
              {...{
                ...organisationForm,
                handleHiddingForm,
                hiddenForm,
                formType: FORM_TYPE.organization,
                setIsFormSentSuccessfully,
              }}
            />
          </GoogleReCaptchaProvider>
          <InfoBanner {...{ infoBanner }} />
        </>
      )}
    </Layout>
  );
};

export const query = graphql`
  query SignUpQuery($lang: String) {
    signUpPage(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      mainCarousel {
        carouselItems {
          ...CarouselItemsType
        }
      }
      additionalInfo {
        ...AdditionalInfoType
      }
      invidualForm {
        ...SignUpFormCardType
      }
      organisationForm {
        ...SignUpFormCardType
      }
      infoBanner {
        ...InfoBannerTypeFragment
      }
      thankYouPage {
        additionalInfo {
          ...AdditionalInfoType
        }
        infoBanner {
          ...InfoBannerTypeFragment
        }
        mainCarousel {
          carouselItems {
            ...CarouselItemsType
          }
        }
      }
    }
  }
`;

export default SignUpPage;
